import { Button, Result } from 'antd'
import React from 'react'

export default function VerifyIdCard({ data }) {
    return (
        <div>
            {data != false && <Result
                status="success"
                title="ID Card is Valid"
                subTitle={`ID card belongs to ${data.employee_name} of NEHHDC`}
            />}
            {data == false && <Result
                status="error"
                title="ID Card is InValid"
                subTitle={`ID card has expired`}
            />}
        </div>
    )
}
