import React from 'react'
import { mount, route, withView, redirect, lazy, map, compose } from 'navi';
import LoginPage from '../Pages/LoginPage';
import Layout from '../Layout';
import { View } from 'react-navi';
import { GetData, PostData } from '../Service/service';
import PrintDashboard from '../Pages/Print/PrintDashboard';
import ChatBox from '../Pages/Home/ChatBox';
import VerifyIdCard from '../Pages/Verify/VerifyIdCard';

export default compose(
    withView((request, context, args) => <Layout currentUser={context.currentUser} authService={context.authService}>
        <View />
        {context.currentUser && <ChatBox currentUser={context.currentUser} authService={context.authService} />}
    </Layout>),
    mount({
        // '/login': map((request, context) => route({
        //     title: 'Login Page',
        //     view: <LoginPage currentUser={context.currentUser} authService={context.authService} />
        // })),
        '/login': map((request, context) => context.currentUser ? redirect('/hr')
            : route({
                title: 'Login Page',
                view: <LoginPage currentUser={context.currentUser} authService={context.authService} />
            })),
        '/': map((request, context) => {
            if (!context.currentUser) {
                return redirect('/login')
            }

            return redirect('/hr')
        }),
        '/hr': lazy(() => import('./SecuredRoutes')),
        '/checkidcard/:code': map((request, context) => {


            let code = request.params.code;


            return PostData(`/checkthisid`, {
                code
            })
                .then((resp) => {



                    return route({
                        title: 'ID Card validation',
                        view: <VerifyIdCard

                            data={resp} />
                    })

                })

        }),
        '/print/:type/:id': map((request, context) => {
            if (!context.currentUser) {
                return route({
                    title: 'Login',
                    view: <LoginPage authService={context.authService} />
                })
            }

            let type = request.params.type;
            let id = request.params.id;

            return GetData(`/${context.authService.getApikey()}/${type}/${id}/getprintdata`)
                .then((resp) => {
                    console.log(resp)
                    if (resp.status == true) {

                        return route({
                            title: 'Print Page',
                            view: <PrintDashboard
                                authService={context.authService}
                                type={type}
                                id={id}
                                printData={resp.status_text} />
                        })
                    }
                })

        })
    })
)