import React, {Component, useEffect, useState} from 'react'
import {Launcher} from 'react-chat-window'
import { useDispatch, useSelector } from 'react-redux';
import { getMessage } from '../../redux/actions';
import { SecuredGetData, SecuredPostData } from '../../Service/service';
 
export default function ChatBox({authService, currentUser}) {

  const messageList = useSelector(state => state.message)
  const isMessageLoaded = useSelector(state => state.isMessageLoaded)
  const dispatch = useDispatch();


  useEffect(()=>{
     if(!isMessageLoaded)
    dispatch(getMessage());
   
  },[isMessageLoaded])
 
 
  function onMessageWasSent(message) {
    let m = {
        ...message,
        author:currentUser.emp_id,
        data:{
          text:'ADMIN' + ' - '+ message.data.text
        }
    };

    SecuredPostData(`/${authService.getApikey()}/addmessage`,m)
    .then((resp)=>{
     dispatch(getMessage());

    })
     
}

  
    return (<div>
      <Launcher
        agentProfile={{
          teamName: 'chat-box',
          imageUrl: 'https://a.slack-edge.com/66f9/img/avatars-teams/ava_0001-34.png'
        }}
        onMessageWasSent={(value)=>onMessageWasSent(value)}
        messageList={messageList}
        showEmoji={false}
      />
    </div>)
  
}