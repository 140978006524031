export const LOAD_APP = "LOAD_APP";
export const PUSH_NEW_APP = "PUSH_NEW_APP";
export const NOTIFICATION_ON = "NOTIFICATION_ON";
export const NOTIFICATION_OFF = "NOTIFICATION_OFF";


export const T_STATUS_SUCCESS = "T_STATUS_SUCCESS";
export const T_STATUS_ERROR = "T_STATUS_ERROR";
export const T_STATUS_LOADING = "T_STATUS_LOADING";
export const T_STATUS_CLOSE_LOADING = "T_STATUS_CLOSE_LOADING";
export const T_STATUS_WARNING = "T_STATUS_WARNING";
export const T_STATUS_INFO = "T_STATUS_INFO";


export const T_STATUS_SHOW = "T_STATUS_SHOW";


export const SET_NEW_FONT = "SET_NEW_FONT";
export const SET_NEW_MENU_KEY = "SET_NEW_MENU_KEY";









export const LOAD_PARTY = "LOAD_PARTY";
export const ADD_PARTY = "ADD_PARTY";
export const EDIT_PARTY = "EDIT_PARTY";









export const ACCESS = "ACCESS";


export const GET_SERVICES = "GET_SERVICES";
export const GET_BANKS = "GET_BANKS";
export const GET_CATEGORY = "GET_CATEGORY";
export const GET_COMPANY_DETAILS = "GET_COMPANY_DETAILS";
export const GET_BRANCH = "GET_BRANCH";
export const GET_DEPARTMENT = "GET_DEPARTMENT";
export const GET_DESIGNATION = "GET_DESIGNATION";
export const GET_GRADE = "GET_GRADE";
export const GET_PAYHEAD = "GET_PAYHEAD";
export const GET_PAYROLL = "GET_PAYROLL";
export const GET_EMPLOYMENT_TYPE = "GET_EMPLOYMENT_TYPE";
export const GET_INSURANCE = "GET_INSURANCE";
export const GET_LEAVE_TYPE = "GET_LEAVE_TYPE";
export const GET_GRIEVANCE_TYPE = "GET_GRIEVANCE_TYPE";
export const GET_SESSION = "GET_SESSION";
export const GET_HOLIDAY = "GET_HOLIDAY";
export const GET_NOTICE = "GET_NOTICE";
export const GET_EMPLOYEE = "GET_EMPLOYEE";
export const GET_LEAVE_LIST = "GET_LEAVE_LIST";
export const GET_EMPLOYEE_LEAVE = "GET_EMPLOYEE_LEAVE";
export const GET_EMPLOYEE_ATTENDANCE = "GET_EMPLOYEE_ATTENDANCE";
export const SOCKET_ON = "SOCKET_ON";
export const GET_MESSAGE = "GET_MESSAGE";
export const GET_FESTIVAL_ADVANCE = "GET_FESTIVAL_ADVANCE";
export const GET_PAYMENT_RECEIPT = "GET_PAYMENT_RECEIPT";

export const TOGGLE_COLLAPSE = "TOGGLE_COLLAPSE";



export const ADD_TEST = "ADD_TEST";
export const ADD_NEW_TERM = "ADD_NEW_TERM";