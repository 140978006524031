import io from 'socket.io-client'
import { fromEvent, Observable } from 'rxjs';
import { WS_SERVER } from './GlobalVariables';
import { authService } from './AuthService';

export default class SocketService {
    socket = {};

    init() {
        this.socket = io(WS_SERVER, {
            transports: ['websocket'],
            query: { id_token: JSON.stringify(authService.getUser()) }
            // transportOptions: {
            //             polling: {
            //                 extraHeaders: { "origin": "https://payrollmonitor.corexx.in" }
            //             },
            //         }
        });

        // {
        //     transportOptions: {
        //         polling: {
        //             extraHeaders: { "Authorization": `Bearer ${authService.getToken()}` }
        //         },
        //     }
        // }

        return this;
    }

    onMessage() {
        return fromEvent(this.socket, 'msgToClient');
    }


    onUserAdd() {
        return fromEvent(this.socket, 'userList');
    }


    onRoomCreation() {
        return fromEvent(this.socket, 'roomCreated');
    }


    roomMessage() {
        return fromEvent(this.socket, 'roomMsg');
    }

    sendMessage(m) {
        return this.socket.emit('msgToServer', m);
    }

    // join 
    joinRoom(roomId, user_id) {
        return this.socket.emit('join', { roomId: roomId, user_id: user_id });
    }



    myType(s) {
        return new Promise((resolve, reject) =>
            this.socket.on(s, (data) => {
                resolve(data)
            })
        )
    }

    myType1(s) {
        return fromEvent(this.socket, s);
    }

    constructor() {
        this.init();
    }

    // send server data to server 
    sendDataToServer(data) {

        return this.socket.emit('sendDataToServer', data);
    }


    // receive server data to server 
    receiveFromServer() {
        return fromEvent(this.socket, 'receiveFromServer');
    }



    // send server data to server 
    createBroadcaster(data) {
        return this.socket.emit('broadcaster', data);
    }


    // client 
    createConnectionClient(data) {

        return this.socket.emit('watcher', data);
    }




    emit() {
        return this.socket;
    }



}