import {
  NOTIFICATION_ON,
  NOTIFICATION_OFF,
  SET_NEW_MENU_KEY,
  T_STATUS_SHOW,
  T_STATUS_LOADING,
  T_STATUS_CLOSE_LOADING,
  SET_NEW_FONT,
  LOAD_PARTY,
  ADD_PARTY,
  EDIT_PARTY,
  ACCESS,
  TOGGLE_COLLAPSE,
  GET_SERVICES,
  GET_BANKS,
  GET_CATEGORY,
  GET_COMPANY_DETAILS,
  GET_BRANCH,
  GET_DEPARTMENT,
  GET_DESIGNATION,
  GET_GRADE,
  GET_EMPLOYMENT_TYPE,
  GET_INSURANCE,
  GET_LEAVE_TYPE,
  GET_GRIEVANCE_TYPE,
  GET_SESSION,
  GET_HOLIDAY,
  GET_NOTICE,
  GET_EMPLOYEE,
  GET_EMPLOYEE_LEAVE,
  GET_EMPLOYEE_ATTENDANCE,
  SOCKET_ON,
  GET_PAYHEAD,
  GET_MESSAGE,
  GET_PAYROLL,
  GET_FESTIVAL_ADVANCE,
  GET_PAYMENT_RECEIPT
} from '../constants/action-types'
import { GetData, SecuredGetData } from '../../Service/service'
import { authService } from '../../Helper/AuthService'
import SocketService from '../../Helper/SocketService'



export function showNotification({ type, status, text,action,link }) {
  return function (dispatch) {
    dispatch({ type: NOTIFICATION_ON, payload: { type, status, text,action,link } })
    setTimeout(() => dispatch({ type: NOTIFICATION_OFF, payload: '' }), 2000)
  }
}

export function changeFontSettings({ fontSettings }) {
  return function (dispatch) {
    dispatch({ type: SET_NEW_FONT, payload: fontSettings })
  }
}

export function showToast({ status, text }) {
  return function (dispatch) {
    dispatch({ type: T_STATUS_SHOW, payload: { status, text } })
  }
}

export function showLoading({ text }) {
  return function (dispatch) {
    dispatch({ type: T_STATUS_LOADING, payload: { text } })
  }
}

export function closeLoading() {
  return function (dispatch) {
    dispatch({ type: T_STATUS_CLOSE_LOADING, payload: '' })
  }
}

export function setNewKey(data) {
  return function (dispatch) {
    dispatch({ type: SET_NEW_MENU_KEY, payload: data })
  }
}

// company details

export function getCompanyDetails() {
  return function (dispatch) {
    return SecuredGetData(`/getcompanydetails`).then((resp) => {
      dispatch({ type: GET_COMPANY_DETAILS, payload: resp })
    })
  }
}

// Branch
export function getBranch() {
  return function (dispatch) {
    if (authService.loggedIn()) {
      return SecuredGetData(`/getbranch`).then(
        (resp) => {
          dispatch({ type: GET_BRANCH, payload: resp })
        },
      )
    }
  }
}

//Department
export function getDepartment() {
  return function (dispatch) {
    if (authService.loggedIn()) {
      return SecuredGetData(`/getdepartment`).then(
        (resp) => {
          dispatch({ type: GET_DEPARTMENT, payload: resp })
        },
      )
    }
  }
}

//Designation
export function getDesignation() {
  return function (dispatch) {
    if (authService.loggedIn()) {
      return SecuredGetData(`/getdesignation`).then(
        (resp) => {
          dispatch({ type: GET_DESIGNATION, payload: resp })
        },
      )
    }
  }
}

//Grade
export function getGrade() {
  return function (dispatch) {
    if (authService.loggedIn()) {
      return SecuredGetData(`/getgrade`).then(
        (resp) => {
          dispatch({ type: GET_GRADE, payload: resp })
        },
      )
    }
  }
}

//Payhead
export function getPayhead() {
  return function (dispatch) {
    if (authService.loggedIn()) {
      return SecuredGetData(`/getpayhead`).then(
        (resp) => {
          dispatch({ type: GET_PAYHEAD, payload: resp })
        },
      )
    }
  }
}

//Payroll
export function getPayroll() {
  return function (dispatch) {
    if (authService.loggedIn()) {
      return SecuredGetData(`/${authService.getApikey()}/getpayroll`).then(
        (resp) => {
          dispatch({ type: GET_PAYROLL, payload: resp })
        },
      )
    }
  }
}

//Employment Type
export function getEmploymentType() {
  return function (dispatch) {
    if (authService.loggedIn()) {
      return SecuredGetData(`/getemploymenttype`).then(
        (resp) => {
          dispatch({ type: GET_EMPLOYMENT_TYPE, payload: resp })
        },
      )
    }
  }
}

//Insurance
export function getInsurance() {
  return function (dispatch) {
    if (authService.loggedIn()) {
      return SecuredGetData(`/getinsurance`).then(
        (resp) => {
          dispatch({ type: GET_INSURANCE, payload: resp })
        },
      )
    }
  }
}

//Leave Type
export function getLeaveType() {
  return function (dispatch) {
    if (authService.loggedIn()) {
      return SecuredGetData(`/getleave`).then(
        (resp) => {
          dispatch({ type: GET_LEAVE_TYPE, payload: resp })
        },
      )
    }
  }
}

//Grievance Type
export function getGrievanceType() {
  return function (dispatch) {
    if (authService.loggedIn()) {
      return SecuredGetData(`/getgrievancetype`).then(
        (resp) => {
          dispatch({ type: GET_GRIEVANCE_TYPE, payload: resp })
        },
      )
    }
  }
}

//Session
export function getSession() {
  return function (dispatch) {
    if (authService.loggedIn()) {
      return SecuredGetData(`/getsession`).then(
        (resp) => {
          dispatch({ type: GET_SESSION, payload: resp })
        },
      )
    }
  }
}

//Holiday
export function getHoliday() {
  return function (dispatch) {
    if (authService.loggedIn()) {
      return SecuredGetData(`/getholiday`).then(
        (resp) => {
          dispatch({ type: GET_HOLIDAY, payload: resp })
        },
      )
    }
  }
}

//Notice
export function getNotice() {
  return function (dispatch) {
    if (authService.loggedIn()) {
      return SecuredGetData(`/getnotice`).then(
        (resp) => {
          dispatch({ type: GET_NOTICE, payload: resp })
        },
      )
    }
  }
}

//Festival Advance
export function getFestivalAdvance() {
  return function (dispatch) {
    if (authService.loggedIn()) {
      return SecuredGetData(`/getfestivaladvance`).then(
        (resp) => {
          dispatch({ type: GET_FESTIVAL_ADVANCE, payload: resp })
        },
      )
    }
  }
}

//PaymentReceipt
export function getPaymentReceipt() {
  return function (dispatch) {
    if (authService.loggedIn()) {
      return SecuredGetData(`/getpaymentreceipt`).then(
        (resp) => {
          dispatch({ type: GET_PAYMENT_RECEIPT, payload: resp })
        },
      )
    }
  }
}


//Employee
export function getEmployee() {
  return function (dispatch) {
    if (authService.loggedIn()) {
      return SecuredGetData(`/getallemployees`).then(
        (resp) => {
          dispatch({ type: GET_EMPLOYEE, payload: resp.status_text })
        },
      )
    }
  }
}

// //Employee Leave
// export function getEmployeeLeave() {
//   return function (dispatch) {
//     if (authService.loggedIn()) {
//       return SecuredGetData(`/getallleavelist`).then(
//         (resp) => {
//           dispatch({ type: GET_EMPLOYEE_LEAVE, payload: resp.status_text })
//         },
//       )
//     }
//   }
// }


//Employee Attendance
export function getEmployeeAttendance() {
  return function (dispatch) {
    if (authService.loggedIn()) {
      return SecuredGetData(`/getattendancelist`).then(
        (resp) => {
          dispatch({ type: GET_EMPLOYEE_ATTENDANCE, payload: resp })
        },
      )
    }
  }
}

export function getMessage() {
  return function (dispatch) {
    return SecuredGetData(`/getmessage`).then((resp) => {
      let t = resp.map((el)=>el.message)
      dispatch({ type: GET_MESSAGE, payload: t })
    })
  }
}

export function initiateSocket() {
  return function (dispatch) {
      let socket = new SocketService();
      dispatch({ type: SOCKET_ON, payload: socket })
  }
}

export function toggleCollapseDashboard(value) {
  //console.log("🚀 ~ file: index.js ~ line 153 ~ toggleCollapseDashboard ~ value", value)
  return function (dispatch) {
    dispatch({ type: TOGGLE_COLLAPSE, payload: value })
  }
}